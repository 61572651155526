import React from 'react';
//import tc1 from '../img/client_dp1.jpg';
//import tc2 from '../img/client_dp2.jpg';
//import tc3 from '../img/client_dp3.jpg';
class Testimonials extends React.Component {
    
    render() { 
        return ( 
            <div>
            <section id="testimonials" className="gray-bg index-2">
              <div className="container">
                <div className="row row-reverse">
                  <div className="col-lg-6 col-md-6 col-xs-12 content_sec">
                    <div className="section_heading">
                      <h2>What <span>People Say</span></h2>
                      <div className="separator left-align">
                        <ul>
                          <li />
                          <li />
                          <li />
                        </ul>
                      </div>
                    </div>
                    <div className="testimonials-slider owl-four owl-carousel owl-theme">
                      <div className="item">
                        <div className="comment-box">
                          <div className="comment">
                            <p>we are getting services in ERP financial services from Wizmen. We are satisfy with them. </p>
                          </div>
                        </div>
                        <div className="client_detail">
                          <div className="client_dp"> <img src="img/sha.png" alt="Client DP" /> </div>
                          <div className="name_designation">
                            <h5>Shaheen Textiles</h5>
                            <span>GM Finance</span> </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="comment-box">
                          <div className="comment">
                            <p>Wizmen System best ERP services provider in our town. </p>
                          </div>
                        </div>
                        <div className="client_detail">
                          <div className="client_dp"> <img src="img/nay.png" alt="Client DP" /> </div>
                          <div className="name_designation">
                            <h5>Nayyer Carpets</h5>
                            <span>GM Finance</span> </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="comment-box">
                          <div className="comment">
                            <p>we are getting services in ERP financial services from Wizmen. We are satisfy with them. </p>
                          </div>
                        </div>
                        <div className="client_detail">
                          <div className="client_dp"> <img src="img/sajjad.png" alt="Client DP" /> </div>
                          <div className="name_designation">
                            <h5>Sajjad Textiles</h5>
                            <span>GM Finance</span> </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="comment-box">
                          <div className="comment">
                            <p>Wizmen System best ERP services provider in our town. </p>
                          </div>
                        </div>
                        <div className="client_detail">
                          <div className="client_dp"> <img src="img/sahir.png" alt="Client DP" /> </div>
                          <div className="name_designation">
                            <h5>Sahir Associates</h5>
                            <span>GM Finance</span> </div>
                        </div>
                      </div>
                     
                     
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix" />
            </section>
            <section id="quick-connect" className="index-2">
              <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="newsletter">
                        <div className="section_heading">
                          <h4>Subscribe Our Newsletter</h4>
                          <div className="separator">
                            <ul>
                              <li />
                              <li />
                              <li />
                            </ul>
                          </div>
                          <div className="heading_content">
                            Provide your email address and stay informed with our latest resources
                          </div>
                        </div>
                        <form>
                          <input type="text" placeholder="Enter your Email Id" />
                          <button type="submit">Subscribe</button>
                        </form>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="quick-info">
                        <div className="section_heading">
                          <h4>For Quick Info</h4>
                          <div className="separator">
                            <ul>
                              <li />
                              <li />
                              <li />
                            </ul>
                          </div>
                        </div>
                        <div className="content">
                          <div className="sub-heading">Call Us On</div>
                          <div className="contact-no">+92-321-9416913</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
         );
    }
}
 
export default Testimonials;