import React from "react";

class clients extends React.Component {
  render() {
    return (
      <section id="client" className="index-2">
        <div className="container">
          <div className="section_heading">
            <h2>Our Clients</h2>
            <div className="separator">
              <ul>
                <li />
                <li />
                <li />
              </ul>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="team-slider owl-three owl-carousel owl-theme "   >
              <div className="item">
                {" "}
                <img src="img/sha.png" alt="Client" class="img-thumbnail" />{" "}
              </div>
             
              <div className="item">
                {" "}
                <img src="img/sajjad.png" alt="Client" class="img-thumbnail" />{" "}
              </div>



              <div className="item">
                {" "}
                <img src="img/nay.png" alt="Client" class="img-thumbnail" />{" "}
              </div>
              <div className="item">
                {" "}
                <img src="img/yums.png" alt="Client" class="img-thumbnail" />{" "}
              </div>
              
              <div className="item">
                {" "}
                <img src="img/salvation.png" alt="Client" class="img-thumbnail" />{" "}
              </div>
              <div className="item">
                {" "}
                <img src="img/united.png" alt="Client" class="img-thumbnail" />{" "}
              </div>
              <div className="item">
                {" "}
                <img src="img/sahir.png" alt="Client" class="img-thumbnail" />{" "}
              </div>
              <div className="item">
                {" "}
                <img src="img/al-raheem.png" alt="Client" class="img-thumbnail" />{" "}
              </div>
              <div className="item">
                {" "}
                <img src="img/nishat.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

              <div className="item">
                {" "}
                <img src="img/crescent.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

              <div className="item">
                {" "}
                <img src="img/crowntyres.png" alt="Client" class="img-thumbnail" />{" "}
              </div>
              <div className="item">
                {" "}
                <img src="img/divinelogo.png" alt="Client" class="img-thumbnail" />{" "}
              </div>


              <div className="item">
                {" "}
                <img src="img/download.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

              <div className="item">
                {" "}
                <img src="img/ecologix.png" alt="Client" class="img-thumbnail" />{" "}
              </div>
              <div className="item">
                {" "}
                <img src="img/fazal.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             

              <div className="item">
                {" "}
                <img src="img/guj.png" alt="Client" class="img-thumbnail" />{" "}
              </div>
              <div className="item">
                {" "}
                <img src="img/ihsan.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

              <div className="item">
                {" "}
                <img src="img/shirjeel.png" alt="Client" class="img-thumbnail" />{" "}
              </div>
              <div className="item">
                {" "}
                <img src="img/client_logos/logo-spice-bazaar-sml.png" alt="Client" class="img-thumbnail" />{" "}
              </div>


              <div className="item">
                {" "}
                <img src="img/super_asia.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/15.png" alt="Client" class="img-thumbnail" />{" "}
              </div>


              <div className="item">
                {" "}
                <img src="img/client_logos/30038Khayaban-E-Amin-Lahore.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/abco.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

              <div className="item">
                {" "}
                <img src="img/client_logos/acro.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/aghs.png" alt="Client" class="img-thumbnail" />{" "}
              </div>




              <div className="item">
                {" "}
                <img src="img/client_logos/textile-mills.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
             


              <div className="item">
                {" "}
                <img src="img/client_logos/alam.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/ambtion.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             

             
              <div className="item">
                {" "}
                <img src="img/client_logos/ark.png" alt="Client" class="img-thumbnail" />{" "}
              </div>




              <div className="item">
                {" "}
                <img src="img/client_logos/asm.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/automotive-spare-acce.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

              <div className="item">
                {" "}
                <img src="img/client_logos/bbj.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/beijing.png" alt="Client" class="img-thumbnail" />{" "}
              </div>



              <div className="item">
                {" "}
                <img src="img/client_logos/beta.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/chikachino.png" alt="Client" class="img-thumbnail" />{" "}
              </div>



              <div className="item">
                {" "}
                <img src="img/client_logos/chikpat.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/ciba_normal.png" alt="Client" class="img-thumbnail" />{" "}
              </div>





              <div className="item">
                {" "}
                <img src="img/client_logos/cpx_54241389788888.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/CPHS.png" alt="Client" class="img-thumbnail" />{" "}
              </div>


              
              <div className="item">
                {" "}
                <img src="img/client_logos/indusjute.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/ghauri.png" alt="Client" class="img-thumbnail" />{" "}
              </div>


              <div className="item">
                {" "}
                <img src="img/client_logos/kentax.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/khalid.png" alt="Client" class="img-thumbnail" />{" "}
              </div>




              <div className="item">
                {" "}
                <img src="img/Kronos-1-300x188.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/lahoreamrericanschool.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

              
              <div className="item">
                {" "}
                <img src="img/client_logos/laptopzone.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/leathertex.png" alt="Client" class="img-thumbnail" />{" "}
              </div>


              <div className="item">
                {" "}
                <img src="img/client_logos/logo-(1).png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/logo-gulshan.png" alt="Client" class="img-thumbnail" />{" "}
              </div>




              <div className="item">
                {" "}
                <img src="img/client_logos/logo.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/logo_03.png" alt="Client" class="img-thumbnail" />{" "}
              </div>



              <div className="item">
                {" "}
                <img src="img/client_logos/Logo1.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
             

              <div className="item">
                {" "}
                <img src="img/client_logos/naeem.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/paramount_logo.png" alt="Client" class="img-thumbnail" />{" "}
              </div>




              <div className="item">
                {" "}
                <img src="img/client_logos/premier.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/Quality-Textile-Mills-Limited-BLACK.png" alt="Client" class="img-thumbnail" />{" "}
              </div>



              <div className="item">
                {" "}
                <img src="img/client_logos/rajasahibdepartmental.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/samintex.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

              <div className="item">
                {" "}
                <img src="img/client_logos/shahzad_textile.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/supreme.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

              <div className="item">
                {" "}
                <img src="img/client_logos/tameer.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/the_lake_city_holdings_pvt_ltd.png" alt="Client" class="img-thumbnail" />{" "}
              </div>


              <div className="item">
                {" "}
                <img src="img/client_logos/tiffany-co.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/ubc.png" alt="Client" class="img-thumbnail" />{" "}
              </div>



              <div className="item">
                {" "}
                <img src="img/client_logos/urdu-bazar.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

             
              <div className="item">
                {" "}
                <img src="img/client_logos/vivastay_lg.png" alt="Client" class="img-thumbnail" />{" "}
              </div>


              <div className="item">
                {" "}
                <img src="img/client_logos/whizzsystems.png" alt="Client" class="img-thumbnail" />{" "}
              </div>
              <div className="item">
                {" "}
                <img src="img/client_logos/akram-textile-mill.png" alt="Client" class="img-thumbnail" />{" "}
              </div>
              <div className="item">
                {" "}
                <img src="img/client_logos/0.png" alt="Client" class="img-thumbnail" />{" "}
              </div>

            </div>
            <div className="left-tri" />
            <div className="right-tri" />
          </div>


        </div>
        <div className="clearfix" />
        <div className="overlay" />
      </section>
    );
  }
}

export default clients;
