import React, { Component } from 'react';
//import Slider from './components/slide';


//import App from '../App' 
//import Header from '../components/head';
//import Slider from '../components/slide';

//import Slider_6 from '../components/slider6';
//import About from '../components/abouts';
//import Services from '../components/service';
//import Work from '../components/work';
//import Team from '../components/team';
//import Portfolio from '../components/port';
//import Pricing from '../components/price';
//import Counter from '../components/count';
//import Blog from '../components/blog';
//import Clients from '../components/clients';
//import Testimonials from '../components/testimonials';
//import Footer from '../components/footer';

//import ContactForm from '../components/contactform';


import Banner from './portfoliopage_components/portfolio_banner'
//import Ports from './portfoliopage_components/portfolio2'
import Ports from './portfoliopage_components/portfolio3'
//import Qualities from './aboutpage_components/abt_qualities'
//import Details from './aboutpage_components/abt_details'
//import ACounter from './aboutpage_components/abt_counter'

const Portfoliopage = () => (
  <div >
  

     
       <div><Banner /></div>
       <div><Ports /></div>
     
       <br/><br/><br/>
</div>
)

export default Portfoliopage
