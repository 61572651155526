import React from "react"; 

class team extends React.Component {
  render() {
    return (
      <section id="our_team" className="index-2">
        <div className="container">
          <div className="section_heading">
            <h2>
              Our <span>Team</span>
            </h2>
            <div className="separator">
              <ul>
                <li />
                <li />
                <li />
              </ul>
            </div>
            <div className="heading_content">
            Our Team is a very balance and blend of Business And IT Professional Including Business Analyst Functional Consultants,Developer,Implementer,QA Personnel,Configration Mangers and Support person.
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-xs-12 content_sec">
            <div className="team-slider owl-one owl-carousel owl-theme">
              <div className="item">
                <div className="client_dp">
                  {" "}
                  <img src="img/amir.png" alt="Team" />{" "}
                </div>
                <div className="team-card">
               <h5> <a>Muhammad Amir</a></h5>
                  <div className="name_designation">
                    <h5> 
                    
                      <a href="team-member-detail.html">ERP TEAM</a>
                    </h5>
                    <span>(MANAGER)</span>{" "}<br/>
                    <span>TECHNICAL</span>{" "}

                  </div>
                  <div className="description">
                    <p >Wizmen develop forms & reports based on functional requirement (business requirement).</p>
                  </div>
                  <div className="social_links">
                    <ul>
                      <li>
                        <a href="javascript:void(0)" title="Facebook">
                          <i className="flat flaticon-facebook-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Twitter">
                          <i className="flat flaticon-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Google">
                          <i className="flat flaticon-google-plus-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Linkedin">
                          <i className="flat flaticon-linkedin-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Instagram">
                          <i className="flat flaticon-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="client_dp">
                  {" "}
                  <img src="img/mubashir.png" alt="Team" />{" "}
                </div>
                <div className="team-card">
                <h5> <a>Mubasher Maqsood </a></h5>
                  <div className="name_designation">
                    <h5>
                      <a href="team-member-detail.html">ERP TEAM</a>
                    </h5>
                    <span>(MANAGER)</span>{" "}<br/>
                    <span>FUNCTIONAL</span>{" "}
                  </div>
                  <div className="description">
                    <p >
                  Wizmen study the client business process to map the same into Oracle Application and to identify the GAPs in the business processes (which cannot be mapped to Oracle Apps).
                    </p>
                  </div>
                  <div className="social_links">
                    <ul>
                      <li>
                        <a href="javascript:void(0)" title="Facebook">
                          <i className="flat flaticon-facebook-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Twitter">
                          <i className="flat flaticon-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Google">
                          <i className="flat flaticon-google-plus-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Linkedin">
                          <i className="flat flaticon-linkedin-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Instagram">
                          <i className="flat flaticon-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="client_dp">
                  {" "}
                  <img src="img/khalil.jpg" alt="Team" />{" "}
                </div>
                <div className="team-card">
                <h5> <a>Khalil Ur Rehman </a></h5>
                  <div className="name_designation">
                  
                    <h5>
                      <a href="team-member-detail.html">Mobile App & Web TEAM</a>
                    </h5>
                    <span>MANAGER</span>{" "}
                  </div>
                  <div className="description">
                    <p>
                    Wizmen has expertise in working on mobile platforms such as android and cross platform apps.<br/>We have worked on social, entertainment, financial, navigational and productivity applications.
                    </p>
                  </div>
                  <div className="social_links">
                    <ul>
                      <li>
                        <a href="javascript:void(0)" title="Facebook">
                          <i className="flat flaticon-facebook-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Twitter">
                          <i className="flat flaticon-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Google">
                          <i className="flat flaticon-google-plus-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Linkedin">
                          <i className="flat flaticon-linkedin-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Instagram">
                          <i className="flat flaticon-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              
              <div className="item">
                <div className="client_dp">
                  {" "}
                  <img src="img/mateen.png" alt="Team" />{" "}
                </div>
                <div className="team-card">
                <h5> <a>Mateen Ahmad</a></h5>
                  <div className="name_designation">
                    <h5>
                      <a href="team-member-detail.html">ERP TEAM</a>
                    </h5>
                    <span>IT MANAGER</span>{" "}
                  </div>
                  <div className="description">
                    <p >Wizmen develop forms & reports based on functional requirement (business requirement).</p>
                  </div>
                  <div className="social_links">
                    <ul>
                      <li>
                        <a href="javascript:void(0)" title="Facebook">
                          <i className="flat flaticon-facebook-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Twitter">
                          <i className="flat flaticon-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Google">
                          <i className="flat flaticon-google-plus-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Linkedin">
                          <i className="flat flaticon-linkedin-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Instagram">
                          <i className="flat flaticon-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="client_dp">
                  {" "}
                  <img src="img/rizwan.jpg" alt="Team" />{" "}
                </div>
                <div className="team-card">
                <h5> <a>Muhammad Rizwan Ansar </a></h5>
                  <div className="name_designation">
                    <h5>
                      <a href="team-member-detail.html">WEB App TEAM</a>
                    </h5>
                    <span>Team Lead</span>{" "}
                  </div>
                  <div className="description">
                    <p>
                    Wizmen offers the best web development and app development services that are user-friendly at affordable rates.
                    </p>
                  </div>
                  <div className="social_links">
                    <ul>
                      <li>
                        <a href="javascript:void(0)" title="Facebook">
                          <i className="flat flaticon-facebook-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Twitter">
                          <i className="flat flaticon-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Google">
                          <i className="flat flaticon-google-plus-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Linkedin">
                          <i className="flat flaticon-linkedin-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Instagram">
                          <i className="flat flaticon-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="client_dp">
                  {" "}
                  <img src="img/ahsan.png" alt="Team" />{" "}
                </div>
                <div className="team-card">
                <h5> <a>Ihsan Ilahi</a></h5>
                  <div className="name_designation">
                    <h5>
                      <a href="team-member-detail.html">ERP TEAM</a>
                    </h5>
                    <span>(MANAGER)</span>{" "}<br/>
                    <span>FUNCTIONAL</span>{" "}
                  </div>
                  <div className="description">
                    <p >
                  Wizmen study the client business process to map the same into Oracle Application and to identify the GAPs in the business processes (which cannot be mapped to Oracle Apps).
                    </p>
                  </div>
                  <div className="social_links">
                    <ul>
                      <li>
                        <a href="javascript:void(0)" title="Facebook">
                          <i className="flat flaticon-facebook-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Twitter">
                          <i className="flat flaticon-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Google">
                          <i className="flat flaticon-google-plus-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Linkedin">
                          <i className="flat flaticon-linkedin-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Instagram">
                          <i className="flat flaticon-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="client_dp">
                  {" "}
                  <img src="img/tanvir.png" alt="Team" />{" "}
                </div>
                <div className="team-card">
                <h5> <a>Tanvir Ahmad </a></h5>
                  <div className="name_designation">
                    <h5>
                      <a href="team-member-detail.html">Human Resource</a>
                    </h5>
                    <span>HR</span>{" "}
                  </div>
                  <div className="description">
                    <p>
                    Wizmen has expertise in working on mobile platforms such as android and cross platform apps.<br/>We have worked on social, entertainment, financial, navigational and productivity applications.
                    </p>
                  </div>
                  <div className="social_links">
                    <ul>
                      <li>
                        <a href="javascript:void(0)" title="Facebook">
                          <i className="flat flaticon-facebook-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Twitter">
                          <i className="flat flaticon-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Google">
                          <i className="flat flaticon-google-plus-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Linkedin">
                          <i className="flat flaticon-linkedin-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Instagram">
                          <i className="flat flaticon-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>




              <div className="item">
                <div className="client_dp">
                  {" "}
                  <img src="img/tahir.png" alt="Team" />{" "}
                </div>
                <div className="team-card">
                <h5> <a>Muhammad Tahir Masood </a></h5>
                  <div className="name_designation">
                    <h5>
                      <a href="team-member-detail.html">Desktop App TEAM</a>
                    </h5>
                    <span>DEVELOPER</span>{" "}
                  </div>
                  <div className="description">
                    <p >
                  Wizmen study the client business process to map the same into Oracle Application and to identify the GAPs in the business processes (which cannot be mapped to Oracle Apps).
                    </p>
                  </div>
                  <div className="social_links">
                    <ul>
                      <li>
                        <a href="javascript:void(0)" title="Facebook">
                          <i className="flat flaticon-facebook-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Twitter">
                          <i className="flat flaticon-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Google">
                          <i className="flat flaticon-google-plus-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Linkedin">
                          <i className="flat flaticon-linkedin-logo" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" title="Instagram">
                          <i className="flat flaticon-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </section>
    );
  }
}

export default team;
