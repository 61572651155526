import React, { Component } from 'react';


class About_Details extends Component {
   
    render() { 
        return (
            <section id="about-detail" className="index-2">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mid-content">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 img-area"> <img src="img/why-choose-us.jpg" alt="Why Choose Us" /> </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 content-area">
                <div className="section_heading">
                  <div className="number">01</div>
                  <h4>Why <span>Choose Us?</span></h4>
                  <div className="separator left-align ">
                    <ul>
                      <li />
                      <li />
                      <li />
                    </ul>
                  </div>
                </div>
                <div className="description">
                  <p>We Provide professional, high quality, reliable and efficient Software Solutions while maintaining the ease and simplicity of usage to all of our customers. Leading the Software industry and guiding users to the new generation of online Business Solutions.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mid-content">
            <div className="row row-reverse">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 img-area"> <img src="img/our-mission.jpg" alt="Our Mission" /> </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 content-area">
                <div className="section_heading">
                  <div className="number">02</div>
                  <h4>Our <span>Mission</span></h4>
                  <div className="separator left-align ">
                    <ul>
                      <li />
                      <li />
                      <li />
                    </ul>
                  </div>
                </div>
                <div className="description">
                  <p>Providing professional, high quality, reliable and efficient Software Solutions while maintaining the ease and simplicity of usage to all of our customers.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mid-content">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 img-area"> <img src="img/our-vision.jpg" alt="Our Mission" /> </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 content-area">
                <div className="section_heading">
                  <div className="number">03</div>
                  <h4>Our <span>Vision</span></h4>
                  <div className="separator left-align ">
                    <ul>
                      <li />
                      <li />
                      <li />
                    </ul>
                  </div>
                </div>
                <div className="description">
                  <p>Leading the Software industry and guiding users to the new generation of online Business Solutions.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

          );
    }
}
 
export default About_Details;