import React, { Component } from 'react';


class About_Content extends Component {
   
    render() { 
        return (
            <section id="about-us-inner" className="index-2">
        <div className="container">
          <h2 className="d-none">Heading</h2>
          <div className="col-lg-12 col-md-12 col-xs-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 img-area"> <img className="img-area" src="img/about_us_2.jpg" alt="About" /> </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 content-area">
                <div className="about-heading">Providing best business solution for growing your business</div>
                <p class="text-justify">Wizmen Systems has been organized on professional lines with Project managers, System architects, software designers, developers, documentation writers, independent quality assurance teams, trainers and configuration management personnel.Our Functional consultants team comprises of chartered accountants, Financial Analyst and Cost Accountants.They have well defined roles and responsibilities to carry out the various necessary steps of software projects design and engineering to ensure good team work, coordination, and timely product delivery.</p>
                <p class="text-justify">The company has highly experienced educated and skilled humanr resources. Our key personnel has vast experience and vission of diversified business domains and software methodologies. We believe in employing hihly professional and experienced staff for all jobs, project management, architecture, design, development and QA.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

          );
    }
}
 
export default About_Content;