import React, { Component } from 'react';


import Header from './components/head';

import Footer from './components/footer';




//import Header from './components/header'
//import Header from './components/header'
import Main from './components/main'
import Top from './components/topBar/topBar'
const App = () => (
  <div>
  <Header/>
  
  <Main/>
  <Footer/>
   
  </div>
)

export default App
