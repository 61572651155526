import React, { Component } from 'react';


class Contact_Map extends Component {
   
    render() { 
        return (
            <div id="map">
    
          
          
            
         <iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=wizmen%20systems&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
          
          
          
          </div>
          );
         
    }
}
 
export default Contact_Map;