import React from "react";




class about extends React.Component {



  render() {

 
    
    return (
      <section id="about-us" className="index-2">
        <div className="container">
          <div className="section_heading">
          <h1>Company  <span>Preface</span></h1>
            <div className="separator">
              <ul>
                <li />
                <li />
                <li />
              </ul>
            </div>
            <div className="heading_content" >
            <p  > Wizmen is multi dimensional solution provider company with major emphases: <br/>
            Highly flexible Wizmen ERP product capable of accommodating various business process in diversified business organizations.
            Tailored made customer centric software solutions development to cater the specific needs of clients.Technical & Functional consultancy and training for Oracle Financial business suite.
            Re Engineering of Legacy Systems
            Software service and support.Wizmen System is an Oracle Partner company,established in 1997, based in Lahore – Pakistan ,holds a track record of successful IT projects. 
           It has proven its name as one the top ERP solution provider by implementing Wizmen ERP at 100+ clients in Pakistan and abroad. Wizmen Systems is also engaged in customization , Support and training of ORACLE Application.
           </p> </div>
          </div>
          <div className="col-lg-12 col-md-12 col-xs-12 content_sec">
            <div className="row row-safari">
              <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12 img-area">
                {" "}
                <img
                  className="img_sec"
                  src="img/about_us_4.jpg"
                  alt="About"
                />{" "}
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 content-area">
              <h5>Why We Are Different</h5>
                <p className="text-justify">
                Our edge over others is that we have in depth knowledge of variety of business domains rather being merely an IT Technologist. Therefore we are in an ideal position to provide specific solution for any business problems.
                </p>
                <h5>Our Resource taring policy</h5>
            <p className="text-justify">
             Our continuous resource training policy ensures that we are adequately equipped with latest advancement in technology.</p>
                <a className="defualt-button view_more" href="about-us.html">
                  View More
                </a>{" "}
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="qualities col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 column">
                <div className="icon">
                  <i className="flat flaticon-reward" />
                </div>
                <div className="description">
              <h5>We are Professional</h5>
              <p  >Our Team is a very balance and blend of Business & IT Professional Including Business Analyst Functional Consultants,Developer,Implementer,QA Personnel,Configration Mangers and Support person.</p>
            </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 column">
                <div className="icon">
                  <i className="flat flaticon-enterprise" />
                </div>
                <div className="description">
                  <h5>Number 1 Company</h5>
                  <p >
                  Top Rated Software Company since 2000-19.ERP Solution Providing Company Wide-ranging e-Commerce, Mobile Application Solutions.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 column">
                <div className="icon">
                  <i className="flat flaticon-certificate" />
                </div>
                <div className="description">
                  <h5>Licensed and Certified</h5>
                  <p >Choosing the right software brand for your organization could be difficult.But selecting an authentic brand helps you out in a better respect.<br/>You not only have got beautiful viable Quality Solution to benefit for many years,You have also saved yourself a good amount of money and time by shopping the Wizmen way. </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 column">
                <div className="icon">
                  <i className="flat flaticon-working-team" />
                </div>
                <div className="description">
                <h5>Leading Provider</h5>
              <p >Wizmen System Provide Smart solutions to Grow your Business And Complete your Dream In Industry. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default about;
